<Page pageContent={false}>
    <Navbar>
        <NavRight>
            <Link on:click={function() {
              signOut();
            }} text="خروج"/>
        </NavRight>
        <center class="width-100">مدیریت</center>

        <Subnavbar inner={false}>
            <Searchbar
                    searchContainer=".virtual-list"
                    searchItem="li"
                    searchIn=".item-title"
            />
            <Button style="width: 30%;height: 100%" on:click={function() {
                refreshList();
            }}>
                <Icon f7="arrow_2_circlepath"/>
            </Button>
        </Subnavbar>
        <!--        disableButton={!theme.aurora}-->
    </Navbar>

    <Toolbar tabbar labels position='bottom'>
        <Link tabLink="#tab-1" tabLinkActive text="درخواست های جدید" iconIos="f7:envelope_fill"
              iconAurora="f7:envelope_fill"
              iconMd="material:email"/>
    </Toolbar>

    <Tabs>
        <Tab id="tab-1" class="page-content" tabActive>
            <Block class="no-margin no-padding">
                <List class="searchbar-not-found">
                    <ListItem title="Nothing found"/>
                </List>
                <List class="searchbar-found"
                      ul={false}
                      medialList
                      virtualList
                      virtualListParams={{
                          items: items,
                          searchAll: searchAll,
                          renderExternal: renderExternal,
                           height: theme.ios ? 63 : (theme.md ? 73 : 46)
                      }}>
                    <ul>
                        {#each vlData.items as item, index (index)}
                            <ListItem
                                    fill popupOpen=".demo-popup-swipe"
                                    mediaItem
                                    link="#"
                                    on:click={function() {
                                     setNumber(item.object);
                                    }}
                                    style={`top: ${vlData.topPosition}px`}
                                    title={item.title}
                                    subtitle={item.subtitle}
                                    virtualListIndex={items.indexOf(item)}/>
                        {/each}
                    </ul>
                </List>
            </Block>
        </Tab>
    </Tabs>

    <Popup class="demo-popup-swipe" swipeToClose>
        <Page>
            <Navbar title="اطلاعات تماس">
                <NavRight>
                    <Link popupClose>بستن</Link>
                </NavRight>
            </Navbar>

            <div style="height: 100%" class="display-flex justify-content-center align-items-center">
                <table class="text-align-center" style="width: 100%">
                    <tr>
                        <td>کد</td>
                    </tr>
                    <tr>
                        <td>{number.ID}</td>
                    </tr>
                    <tr>
                        <td>شماره تماس</td>
                    </tr>
                    <tr>
                        <td>{number.user_number}</td>
                    </tr>
                    <tr>
                        <td>وضعیت</td>
                    </tr>
                    <tr>
                        <td>{number.status_flag}</td>
                    </tr>
                    <tr>
                        <td>شماره تماس</td>
                    </tr>
                    <tr>
                        <td>{number.time}</td>
                    </tr>
                    <tr>
                        <td>
                            وضعیت
                        </td>
                    </tr>
                    <tr>

                        <Segmented raised tag="p">
                            <Button on:click={function() {
                                changState(number.ID,number.state[0])
                            }} text="{number.state[0]}"/>
                            <Button on:click={function() {
                                changState(number.ID,number.state[1])
                            }} text="{number.state[1]}"/>
                        </Segmented>
                        <Segmented raised tag="p">
                            <Button on:click={function() {
                                changState(number.ID,number.state[2])
                            }} text="{number.state[2]}"/>
                            <Button on:click={function() {
                                changState(number.ID,number.state[3])
                            }} text="{number.state[3]}"/>
                        </Segmented>

                    </tr>
                </table>
            </div>
        </Page>
    </Popup>

    <LoginScreen
            class="demo-login-screen"
            opened={loginScreenOpened}
            onLoginScreenClosed={() => loginScreenOpened = false}>
        <Page loginScreen>
            <LoginScreenTitle>پنل ورود</LoginScreenTitle>
            <List form>
                <ListInput
                        label="Username"
                        type="text"
                        value="{username}"
                        placeholder="Your username"
                        onInput={(e) => username = e.target.value}/>
                <!--value={username}-->
                <ListInput
                        label="Password"
                        type="password"
                        value="{password}"
                        placeholder="Your password"
                        onInput={(e) => password = e.target.value}/>
                <!--value={password}-->
            </List>
            <List>
                <ListButton style="font-weight: bold" title="ورود" onClick={function() {
                    signIn(username,password);
                }}/>
            </List>
        </Page>
    </LoginScreen>
</Page>
<script>
    const PREFIX_KEY = 'signIn-key';
    import {LoginScreenTitle, BlockFooter, ListButton, List, ListInput} from 'framework7-svelte'
    import {
        Page, Button, NavRight, Navbar, Toolbar, Link, BlockTitle, Block, Tabs, Tab, LoginScreen
    } from 'framework7-svelte';
    import {f7, Searchbar, Subnavbar, ListItem, Icon, theme, Popup, Segmented} from 'framework7-svelte'
    import {f7ready} from 'framework7-svelte'
    import {onMount} from 'svelte'

    import WebServices from '../js/WebServices';

    const LogIn = WebServices.function.LogIn();
    let loginScreenOpened = localStorage.getItem(PREFIX_KEY) == null;
    let username = '';
    let password = '';

    function signIn() {
        if (username.length > 0 && password.length > 0) {
            if (LogIn.LogIn(username, password)) {
                f7.dialog.alert("خوش آمدید.", () => {
                    f7.loginScreen.close();
                    localStorage.setItem(PREFIX_KEY, "");
                });
            } else username = password = "";
        } else username = password = "";
    }

    function setNumber(object) {
        number.ID = object.ID;
        number.user_number = object.user_number;
        number.time = object.time;
        number.status_flag = object.status_flag;
    }

    const number = {
        ID: '',
        user_number: '',
        time: '',
        status_flag: '',
        state: WebServices.method.LoadNewRequest.state
    };

    function signOut() {
        localStorage.removeItem(PREFIX_KEY);
        f7.loginScreen.open('.demo-login-screen')
    }

    function changState(ID, state) {
        RequestMethod.ChangeRequestState(ID, state, function (json) {
            if (json[0]) alert("انجام شد.");
            number.status_flag = state;
            refreshList();
        })
    }

    const RequestMethod = WebServices.function.RequestMethod();

    let vlData = {
        items: []
    }, items = [];

    let virtualList;
    onMount(() => {
        f7ready((app) => {
            virtualList = f7.virtualList.get('.searchbar-found');
            RequestMethod.LoadNewRequest((json) => add(json));
        });
    });

    function add(json) {
        for (let i = 0; i < json.length; i++) {
            const o = json[i];
            items.push({
                title: o.user_number,
                subtitle: o.status_flag,
                object: o
            });
        }
        vlData.items = items;
        virtualList.update();
        canUpdate = true;
    }

    let canUpdate = false;

    function refreshList() {
        if (canUpdate) {
            canUpdate = false;
            items = [];
            virtualList.replaceAllItems(items);
            RequestMethod.LoadNewRequest((json) => add(json));
        }
    }

    function searchAll(query, items) {
        const found = [];
        for (let i = 0; i < items.length; i += 1) {
            if (items[i].title.toLowerCase().indexOf(query.toLowerCase()) >= 0 || query.trim() === '') found.push(i);
        }
        return found;
    }

    function renderExternal(virtualList, virtualListData) {
        vlData = virtualListData;
    }
</script>