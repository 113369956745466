import HomePage from '../pages/home.svelte';

const routes = [
    {
        path: '/',
        component: HomePage,
    }
];

export default routes;
