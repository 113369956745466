const WebServices = {
    // base: 'https://android.tourism.digihelp.net/index.php/',
    base: 'http://ussd.digihelp.net/ShoppingServices/index.php/',
    method: {
        'InsertNewRequest': {'name': 'InsertNewRequest'},
        'CheckRequestIsOpen': {'name': 'CheckRequestIsOpen'},
        'ChangeRequestState': {'name': 'ChangeRequestState'},
        'RemoveRequest': {'name': 'RemoveRequest'},
        'Search': {'name': 'Search'},
        'LoadNewRequest': {
            'name': 'LoadNewRequest', state: [
                'درانتظار تماس',
                'لغو درخواست',
                'ثبت سفارش',
                'در دست برسی'
            ]
        },
        'LoadOperatorRequest': {'name': 'LoadOperatorRequest'},
    },
    function: {
        LoadData: function (request, callback, error = null) {
            const url = WebServices.base + request;
            (async (address) => {
                let response = await fetch(address);
                if (response.ok) {
                    let json = await response.json();
                    // if (json.length === 1) json = json[0];
                    // if (json.hasOwnProperty('response')) json = json.response;
                    if (typeof callback == "function") callback(json);
                } else if (typeof error == "function") error(response);
            })(url);
        }, RequestMethod: function () {
            const LoadNewRequest = function (callback) {
                const method = WebServices.method;
                WebServices.function.LoadData(method.LoadNewRequest.name + `/`, function (json) {
                    if (typeof callback == "function") callback(json);
                });
            }, ChangeRequestState = function (ID, state, callback) {
                const method = WebServices.method;
                WebServices.function.LoadData(method.ChangeRequestState.name + `/${ID}/${state}/`, function (json) {
                    if (typeof callback == "function") callback(json);
                });
            };
            return {LoadNewRequest, ChangeRequestState};
        }, LogIn: function () {
            const LogIn = (user, pass) => {
                if (user === pass) {
                    switch (user) {
                        case 'kashancard1':
                        case 'kashancard2':
                        case 'kashancard3':
                            return true;
                        default:
                            return false;
                    }
                }
            };
            return {LogIn};
        }
    }
};

export default WebServices;